<template>
    <b-modal id="modalSendFileToChat" no-fade scrollable no-close-on-backdrop content-class="shadow"  header-class="flex-column mb-0 pb-0 no-border"    centered title="Uploading 1 Item">
        <template #modal-header>
            <div class="d-flex justify-content-start align-content-center">
                <span class="cursor-pointer px-2" @click="close()">
                    <img src="@/assets/images/icon/Close.svg" alt width="100%" data-toggle="tooltip" title="Close"/>
                </span>
                <p class="ml-3 default-text-style-encrypt">Send file to chat</p>
                <!-- <p class="ml-3 default-text-style-encrypt">Copy to Personal {{ selectedField.length }}</p> -->
            </div>
            <Breadcumb noclear="noclear"/>
        </template>
        <div class="d-flex flex-column mt-0">
            <b-list-group v-if="workgroup.type == 'PRIVATE'" class="folder-list-group">
                <b-list-group-item button v-for="(list) in filterList" :key="list._id" @click="clickers(list)">
                    <div class="d-flex justify-content-between align-items-center cursor-pointer py-1" data-toggle="tooltip" :title="list.file_name">
                    <!-- <div class="d-flex justify-content-between align-items-center cursor-pointer py-1" @dblclick="(counter += 1), doubleClick(list)" data-toggle="tooltip" :title="list.file_name"> -->
                        <div class="justify-content-start file-name-folder">
                            <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                            <span class="mx-2 default-text-style-copy-data file-name-folder">{{list.file_name}}</span>
                        </div>
                        <img v-if="list.isFolder" src="@/assets/images/icon/chevLeft.svg" alt="">
                    </div>
                </b-list-group-item>
            </b-list-group>
            <b-list-group v-if="workgroup.type == 'STANDART' || workgroup.type == 'STANDARD'" class="folder-list-group">
                <b-list-group-item button v-for="(list) in listings" :key="list._id" @click="clickers(list)">
                    <div class="d-flex justify-content-between align-items-center cursor-pointer py-1" data-toggle="tooltip" :title="list.file_name">
                    <!-- <div class="d-flex justify-content-between align-items-center cursor-pointer py-1" @dblclick="(counter += 1), doubleClick(list)" data-toggle="tooltip" :title="list.file_name"> -->
                        <div class="justify-content-start file-name-folder">
                            <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                            <span class="mx-2 default-text-style-copy-data file-name-folder">{{list.file_name}}</span>
                        </div>
                        <img v-if="list.isFolder" src="@/assets/images/icon/chevLeft.svg" alt="">
                    </div>
                </b-list-group-item>
            </b-list-group>
            <div v-if="listings.length <= 0" class="ml-4">
                <span class="empty-folder-v3">This folder is empty.</span>
            </div>
        </div>
        
        <template #modal-footer>
            <button :disabled="!selectedFile" :class="selectedFile ? 'btn-copy-v3' : 'disabled-btn-copy-v3'" @click="openSendFile()">Select</button>
        </template>
    </b-modal>
</template>

<script>
import Breadcumb from "./breadcumbWorkgroup.vue";

export default {
    components:{Breadcumb},
    computed:{
        parent_id(){
            return this.$store.getters["workgroup/parent_id"];
        },
        breadcumbs(){
            return this.$store.getters["workgroup/breadcumbs"];
        },
        workgroup(){
            return this.$store.getters["workgroup/workgroup"]
        },
        listings(){
            var items = this.$store.getters['workgroup/items'];
            // items = items.filter((v)=>{
            //     return v.isFolder
            // })
            return items;
        },
        filterList(){
            var items = this.listings
            var workgroup = this.workgroup
            var idUser = this.$store.getters["user/id"]
            
            if(idUser == workgroup.owner_id){
                return items
            } else {
                // var filterItems = items.filter(item => item.added_by == idUser || item.isFolder)
                var filterItems = items.filter(item => item.added_by == idUser)
                return filterItems
            }
        },
        hashMove(){
            return this.$store.getters["workgroup/hashMove"];
        },
        item(){
            return this.$store.getters["storage/item"];
        },
        selectedField() {
            return this.$store.getters['storage/selected']
        },
        state(){
            return this.$store.getters['state/state'];
        }
    },
    data() {
        return {
            clickTimer: null,
            counter: 0,
            selectedFile: false
        }
    },
    methods:{
        openSendFile(){
            this.$ga.event(process.env.VUE_APP_GA_WG_CHAT_GET_FILE_SELECT, 'WG - Click Button Select in Get File Chat', 'wg-chat')
            this.$root.$emit('bv::hide::modal', 'modalSendFileToChat')
            this.$root.$emit('bv::show::modal', 'modal-send-to-chat')
            this.selectedFile = false
        },
        close(){
            this.$ga.event(process.env.VUE_APP_GA_WG_CHAT_FILE_CANCEL, 'WG - Click Button Cancel in Get File Chat', 'wg-chat')
            this.$root.$emit('bv::hide::modal', 'modalSendFileToChat')
            this.$store.commit("storage/SET_ITEM",null)
            this.selectedFile = false
        },
        clickers(item){
            this.counter++
            if (this.counter === 1) {
                this.clickTimer = setTimeout(() => {
                    // this.listings = this.listings.map((v)=>{
                    //     return {
                    //         ...v,
                    //         checked : v._id == item._id ? true : false 
                    //     }
                    // })
                    // this.$store.commit("storage/SET_ONE_SELECTED",item)
                    if(!item.isFolder){
                        this.$store.commit("storage/SET_ITEM",item)
                        this.selectedFile = true
                    } else {
                        this.selectedFile = false
                    }
                    this.counter = 0
                }, 300)
            } else if (this.counter === 2) {
                clearTimeout(this.clickTimer)
                this.counter = 0
                this.doubleClick(item)
            }
        },
        doubleClick(item){
            if(item.isFolder){
                this.$store.commit(this.state+"/SET_PARENT_ID",item._id);
                this.$store.dispatch(this.state+"/listing","noclear")
            } else {
                this.$store.commit("storage/SET_ITEM",item)
                this.$root.$emit('bv::show::modal', 'modal-send-to-chat')
                this.selectedFile = true
            }
        },
    }
}
</script>

<style scoped>
</style>