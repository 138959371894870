<template>
  <b-modal id="modal-delete-message" no-close-on-backdrop hide-header hide-footer centered>
    <div class="d-flex flex-column">
      <span class="title-modal-delete-msg mb-3">Delete this message</span>
      <span class="sub-title-modal-delete-msg">This message will be deleted from the workgroup chat.</span>
    </div>
    <div class="mt-3 pt-3 d-flex justify-content-end align-items-center">
        <a @click="close()" class="cursor-pointer modal-delete-msg-text mr-3"><span class="main-color-api">Cancel</span></a>
        <button @click="deleteMsg()" class="btn-modal-delete-msg">
            <!-- <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> -->
            <span class="modal-delete-msg-text text-white">Delete</span>
        </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  // props: ['item'],
  computed: {
    item() {
      return this.$store.getters["chat/deleteMsg"]
    }
  },
  methods: {
    close(){
      this.$root.$emit('bv::hide::modal', 'modal-delete-message')
      this.$store.commit('chat/SET_DELETE_MESSAGE', null)
    },
    deleteMsg(){
      this.$store.dispatch("chat/removeChat", this.item)
      .then(()=>{
        this.$root.$emit('bv::hide::modal', 'modal-delete-message')
        this.$store.commit('chat/SET_DELETE_MESSAGE', null)
      })
    }
  }
}
</script>

<style>
.title-modal-delete-msg{
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: 500;
  color: #262A2C;
}
.sub-title-modal-delete-msg{
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-weight: 500;
  color: #262A2C;
}
.btn-modal-delete-msg{
  border: none;
  padding: 8px 16px;
  background: #D42020;
  border-radius: 4px;
}
.modal-delete-msg-text{
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: 500;
}
</style>