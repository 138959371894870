<template>
  <Layout ref="layout">
      <div v-if="isWorkgroupSuspend != true" class="header">
          <div class="d-flex justify-content-between align-items-center">
            <p>{{onlineText}}</p>
          </div>
          <div class="mb-2 line-seperate">
              <hr class="horizontal-line">
          </div>
      </div>
      <div v-if="allChat.length <= 0 && isWorkgroupSuspend != true" class="chatBoxEmpty">
          <div class="row">
              <div class="col-12 d-flex justify-content-center">
                  <img src="@/assets/images/emptyMessage.svg" alt="">
              </div>
          <div class="col-12 emptyMsgInfo d-flex justify-content-center">
              <p>No messages to show<br></p>
          </div>
          <div class="col-12 emptyMsgInfo d-flex justify-content-center">
              <p><span>Let's send your first message.</span></p>
          </div>
          </div>
      </div>
      <div v-if="allChat.length > 0 && isWorkgroupSuspend != true" id="chatBoxId" class="chatBox" ref="chatArea">
          <div v-if="(allChat.length) < group.totalChats">
              <p class="btn btnLoad main-color-api" @click="loadHistories">Load more</p>
          </div>
          <div v-for="(messages,index) in sortPerDay" :key="index" class="perDay">
              <div v-if="convertToDay(messages[0].time) == newDate" class="days">
                  <p>Today</p>
              </div>
              <div v-else-if="convertToDay(messages[0].time) == getYesterday" class="days">
                  <p>Yesterday</p>
              </div>
              <div v-else class="days">
                  <p>{{convertToDay(messages[0].time)}}</p>
              </div>
              <div v-for="message in messages" :key="message.time">
                  <div class="d-flex justify-content-start">
                      <div v-if="message.user != getUser" class="mr-2 avatar friendAvatar">
                          <b-avatar class="main-btn-api" size="24px">{{ message.name.charAt(0).toUpperCase() }}</b-avatar>
                      </div>
                      <div class="d-flex w-100">
                          <div class="message" :class="message.user == getUser ? 'myMessage' : 'friendMessage'">
                              <span v-if="message.user == getUser && message.deleted == false" class="more-btn-chat-me">
                                <b-dropdown dropleft class="cursor-pointer" id="btn-chat-me-id">
                                  <template #button-content>
                                      <img class="" src="@/assets/images/icon/More.png" alt="" height="24px">
                                  </template>
                                  <b-dropdown v-if="message.file != null" id="sub-btn-chat-me-id">
                                    <template #button-content>
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open</span>
                                      <img src="@/assets/images/icon/chevLeft.svg" alt="" class="ml-5">
                                    </template>
                                    <b-dropdown-item-button @click="open(message)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open file</span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button @click="openLocation(message)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open location</span>
                                    </b-dropdown-item-button>
                                  </b-dropdown>
                                  <b-dropdown-item-button v-if="message.file != null" @click="downloadFile(message)">
                                    <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                    <span class="ml-3 default-text-style">Download</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button @click="deleteChat(message)">
                                    <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                    <span class="ml-3 default-text-style red">Delete</span>
                                  </b-dropdown-item-button>
                                </b-dropdown>
                              </span>  
                              <div>
                                  <span v-if="message.user != getUser" class="halfOpacity">{{message.name ? message.name : message.user}}<br></span>
                                  <div class="files-in-chat-display mb-2" v-if="message.file != null && message.deleted == false" @dblclick="counter += 1, canOpen(message)">
                                    <img v-if="message.file.encrypt == null" :src="require(`@/assets/images/icon/${getImage(message.file.extension)}`)" alt=""/>
                                    <img v-else-if="message.file.encrypt != null && (message.file.encrypt.code != 2 && message.file.encrypt.code != 4 && message.file.encrypt.code != 7)" :src="require(`@/assets/images/icon/${getImage(message.file.extension)}`)" alt=""/>
                                    <img v-else src="@/assets/images/icon/lock-encrypt.svg" alt="">
                                    <span class="d-flex flex-column ml-2 text-wrap-v3">
                                      <span class="info-file-name-send-chat text-wrap-v3">{{message.file.file_name}}</span>
                                      <span class="info-file-size-send-chat">{{getSize(message.file.size)}}</span>
                                    </span>
                                  </div>
                                  <span v-if="message.deleted == false" class="textBlack mt-1" v-html="message.messageHTML"></span>
                                  <span v-if="message.deleted == true" class="d-flex mt-1">
                                    <img class="mr-2" src="@/assets/images/icon/fi-rr-cross-circle.svg" alt="">
                                    <span class="textDeleted" v-html="message.messageHTML"></span>
                                  </span>
                              </div>
                              <span v-if="message.user != getUser && message.deleted == false && currentWorkgroup.type != 'PRIVATE'" class="more-btn-chat-friend">
                                <b-dropdown v-if="message.file != null" dropright class="cursor-pointer" id="btn-chat-friend-id">
                                  <template #button-content>
                                      <img class="" src="@/assets/images/icon/More.png" alt="" height="24px">
                                  </template>
                                  <b-dropdown v-if="message.file != null" id="sub-btn-chat-friend-id">
                                    <template #button-content>
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open</span>
                                      <img src="@/assets/images/icon/chevLeft.svg" alt="" class="ml-5">
                                    </template>
                                    <b-dropdown-item-button @click="open(message)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open file</span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button @click="openLocation(message)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open location</span>
                                    </b-dropdown-item-button>
                                  </b-dropdown>
                                  <b-dropdown-item-button v-if="message.file != null" @click="downloadFile(message)">
                                    <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                    <span class="ml-3 default-text-style">Download</span>
                                  </b-dropdown-item-button>
                                </b-dropdown>
                              </span>  
                              <span v-if="message.user != getUser && message.deleted == false && currentWorkgroup.type == 'PRIVATE' && (isOwner || message.file.added_by == getUser)" class="more-btn-chat-friend">
                                <b-dropdown v-if="message.file != null" dropright class="cursor-pointer" id="btn-chat-friend-id">
                                  <template #button-content>
                                      <img class="" src="@/assets/images/icon/More.png" alt="" height="24px">
                                  </template>
                                  <b-dropdown v-if="message.file != null" id="sub-btn-chat-friend-id">
                                    <template #button-content>
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open</span>
                                      <img src="@/assets/images/icon/chevLeft.svg" alt="" class="ml-5">
                                    </template>
                                    <b-dropdown-item-button @click="open(message)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open file</span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button @click="openLocation(message)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Open location</span>
                                    </b-dropdown-item-button>
                                  </b-dropdown>
                                  <b-dropdown-item-button v-if="message.file != null" @click="downloadFile(message)">
                                    <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                    <span class="ml-3 default-text-style">Download</span>
                                  </b-dropdown-item-button>
                                </b-dropdown>
                              </span>  
                          </div>
                      </div>
                  </div>
                  <div class="d-flex">
                      <div v-if="message.user != getUser" class="dates"></div>
                      <div class="w-100">
                          <p><span :class="message.user == getUser ? 'myTime' : 'friendTime'">{{convertDate(message.time)}}</span></p>
                      </div>
                      <div v-if="message.user == getUser" class="dates"></div>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="isWorkgroupSuspend != true" class="chatInput">
          <div class="d-flex justify-content-between">
              <textarea 
                  name="" id=""
                  cols="30" 
                  rows="10"
                  placeholder="Send a message..."
                  @input="resize($event)"
                  required
                  v-model="text"
                  @keyup.enter="sendMessage($event)"></textarea>
              <img v-if="canAccess" @click="openListFiles()" class="btn" src="@/assets/images/icon/fi-rr-clip.svg" alt="">
              <img v-else class="btn" src="@/assets/images/icon/fi-rr-clip.svg" alt="">
              <button :disabled="text == ''" @click="sendButton" class="btn-send-chat-v3 main-btn-api">Send</button>
          </div>
      </div>
    <SuspendWorkgroupPage v-if="isWorkgroupSuspend == true"/>
    <ModalSendFilesChat/>
    <ModalSendToChat/>
    <Download/>
    <ImageView/>
    <VideoView/>
    <AudioView/>
    <DeleteMsgAlert :item="this.item"/>
    <Decrypt :item="this.item"/>
    <DecryptNote class="dcrypt-note-chat"/>
  </Layout>    
</template>

<script>
import router from "@/router";
import Layout from "../../layouts/main.vue"
import ModalSendFilesChat from "@/components/widgets/sendFilesChat"
import ModalSendToChat from '@/router/views/chat/modalSendFiles.vue';
import Download from "@/components/widgets/download.vue";
import ImageView from "@/components/widgets/imageView.vue";
import VideoView from "@/components/widgets/videoView.vue";
import AudioView from "@/components/widgets/audioView.vue";
import Decrypt from "@/components/decrypt.vue";
import DecryptNote from "@/components/widgets/decryptNote.vue";
import DeleteMsgAlert from "@/components/widgets/modalDeleteMessage.vue"
import moment from 'moment'

import SuspendWorkgroupPage from '../storage/settings/suspendWorkgroup.vue'

import firebase from "firebase/app";
import "firebase/messaging";

import Storage from '@/api/storage'
import Helper from "@/api/helper"

export default {
  name: 'chat',
  components: {
    Layout,
    ModalSendFilesChat,
    ModalSendToChat,
    Download,
    ImageView,
    VideoView,
    AudioView,
    Decrypt,
    DecryptNote,
    DeleteMsgAlert,
    SuspendWorkgroupPage
  },
  data() {
    return {
      item: null,
      isMenuCondensed: false,
      name: 'Friend A',
      time: null,
      text: '',
      currentUser: '627a0a9fc734f618755f6d76',
      maxTextLength: 255,
      messages: [],
      showParticipants1: false,
      showProperties1: false,
      showPropertiesShare1: false,
      isSubBtnChatMe: false,
      isSubBtnChatFriend: false,
      counter: 0
    };
  },
  computed : {
    isWorkgroupSuspend() {
      return this.$store.getters['workgroup/statusSuspendWorkgroup']
    },
    onlineText(){
      var group = this.$store.getters['workgroup/workgroup'] ? this.$store.getters['workgroup/workgroup'] : JSON.parse(localStorage.getItem('workgroup'))
      var online = group.participant.filter(v=>v.online).length
      return `${group.participant.length} Participants • ${online} Online`
    },
    newDate(){
      var date = new Date();
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth()+1).toString();
      var dd  = date.getDate().toString();

      var mmChars = mm.split('');
      var ddChars = dd.split('');

      var newdate = (ddChars[1]?dd:"0"+ddChars[0]) + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + yyyy;

      return newdate
    },
    getYesterday(){
      var date = new Date()
      date.setDate(date.getDate() - 1)
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth()+1).toString();
      var dd  = date.getDate().toString();

      var mmChars = mm.split('');
      var ddChars = dd.split('');

      var newdate = (ddChars[1]?dd:"0"+ddChars[0]) + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + yyyy;

      return newdate
    },
    exChat(){
      return this.messages
    },
    getFullname(){
      return this.$store.getters['user/fullname']
    },
    getUser(){
      return this.$store.getters['user/id']
    },
    listParticipant(){
      return this.$store.getters['workgroup/workgroup'] ? this.$store.getters['workgroup/workgroup'] : JSON.parse(localStorage.getItem('workgroup'))
    },
    listParticipants(){
      return this.$store.getters['workgroup/workgroups'] ? this.$store.getters['workgroup/workgroups'] : JSON.parse(localStorage.getItem('workgroupList'))
    },
    participant(){
      return this.$store.getters['workgroup/participants']
    },
    workgroupParticipant(){
      return this.$store.getters['chat/allParticipants']
    },
    userChat(){
      var users = this.$store.getters['chat/sortParticipant']
      var userOnline = users.filter(user => user.online == true)
      return userOnline
    },
    allChat(){
      var chats = this.$store.getters['chat/userMessage']
      let workgroupId = this.$store.getters['workgroup/workgroup'] ? this.$store.getters['workgroup/workgroup']._id : JSON.parse(localStorage.getItem('workgroup'))._id
      chats = chats.filter((v) => {
        return v.group == workgroupId
      })
      var chatArr = chats.sort(function(a,b){return b.time - a.time})
      // var allChats = chats.reverse()
      // return allChats
      chatArr = chatArr.map(ele =>{
        let word = ele.message.split(' ')
        word = word.map(w => {
          if(this.isValidUrl(w)){
          // if(w.includes("http")){
            let newWord = `<a href="${w}" target="_blank">` + ' ' + w + ' ' + '</a>'
            return newWord
          }
          return w
        })
        let newMessage = word.join(' ')
        ele.messageHTML = newMessage
        return ele
      })
      // return chatArr
      return chatArr.reverse()
    },
    sortPerDay(){
      this.$store.dispatch('chat/sortPerDay', this.allChat)
      return this.$store.getters["chat/chatByDay"]
    },
    chatByDay(){
      var messages = this.allChat
      var messageByDay = this.groupingChat(messages,'date')
      var obj = messageByDay
      var array = Object.keys(obj).map(function(key) {
        return obj[key];
      })
      // return messageByDay
      return array
    },
    group(){
      return this.$store.getters['chat/group']
    },
    totalCharacters(){
      return this.text.length
    },
    showProperties(){
        return this.$store.getters['storage/showProperties'];
    },
    showPropertiesShare(){
        return this.$store.getters['share/showProperties'];
    },
    showParticipants(){
        return this.$store.getters['workgroup/showParticipants'];
    },
    state(){
        return this.$store.getters['state/state'];
    },
    canAccess(){
        var id = this.$store.getters['user/id'];
        var group = this.$store.getters['workgroup/workgroup'] ? this.$store.getters['workgroup/workgroup'] : JSON.parse(localStorage.getItem('workgroup'))
        var st = this.$store.getters['state/state'];
        if(st == "storage"){
          return true
        }else{
          if(id == group.owner_id){
            return true;
          }else{
            return group.access_type != "View";
          }
        }
    },
    isOwner(){
        var id = this.$store.getters['user/id'];
        var group = this.$store.getters['workgroup/workgroup'] ? this.$store.getters['workgroup/workgroup'] : JSON.parse(localStorage.getItem('workgroup'))

        return id == group.owner_id
    },
    currentWorkgroup(){
        return this.$store.getters['workgroup/workgroup'] ? this.$store.getters['workgroup/workgroup'] : JSON.parse(localStorage.getItem('workgroup'))
    }
  },
  watch: {
    // text() {
    //   this.text = this.text.substring(0, this.maxTextLength)
    // }
  },
  created() {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    getWorkgroup(){
      if(this.listParticipant){
        this.$store.dispatch("workgroup/retrieve", this.listParticipant._id)
      } else {
        this.$store.dispatch("workgroup/retrieve", this.$route.query.wgId)
        .then(()=>{
          this.$store.dispatch('chat/isConnectedSocket')
          .then((res)=>{
            if(res == false){
              this.$store.dispatch('user/getSocket')
              .then(() => {
                this.$store.dispatch('chat/openChat')
              })
            }
          })
        })
      }
    },
    sendInfoDecrypt(item){
      var payload = {
        _id: item.file.id,
        directory: item.file.directory,
        encrypt: item.file.encrypt,
        extension: item.file.extension,
        file_name: item.file.file_name,
        hash_name: item.file.hash_name,
        parent_id: item.file.parent_id,
        size: item.file.size,
        type: item.file.type
      }
      this.item = payload
      this.$root.$emit('bv::show::modal', 'modal-decrypt')
    },
    downloadFile(list){
        this.$store.dispatch("storage/fileretrieve",{_id : list.file.id,directory:list.file.directory})
        .then(()=>{
          this.$root.$emit('bv::show::modal', 'modalDownload');
        })
    },
    openLocation(list){
      if(list.file.parent_id == ''){
          this.$store.commit(this.state+"/SET_PARENT_ID","");
          this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
          this.$router.push({ name: "home" }); 
      }else{
        this.$store.commit(this.state+"/SET_PARENT_ID",list.file.parent_id);
        // this.$store.commit(this.state+"/SET_BREADCUMB",list.parent_folder);
        this.$store.dispatch(this.state+"/listing")
        this.$router.push({ name: "home" }).catch({}); 
      }
    },
    canOpen(item){
      if(this.currentWorkgroup.type != 'PRIVATE'){
        if(this.canAccess){
          this.open(item)
        }
      } else {
        if(this.isOwner || item.file.added_by == this.getUser){
          this.open(item)
        }
      }
    },
    open(list){
        if(list.file.encrypt == null || (list.file.encrypt.code != 2 && list.file.encrypt.code != 4 && list.file.encrypt.code != 7)){
          this.openFile(list)
        } else {
          if(this.canAccess){
            this.sendInfoDecrypt(list)
          }
        }
    },
    openFile(list){
          if(Helper.supportOnlyOffice(list.file.extension)){
              if(this.canAccess){
                this.$store.commit("storage/SET_CAN_ACCESS_FILE", true)
                localStorage.setItem("access_type", JSON.stringify("edit"))
              }else{
                this.$store.commit("storage/SET_CAN_ACCESS_FILE", false)
                localStorage.setItem("access_type", JSON.stringify("view"))
              }
              var payloadFile = {
                _id: list.file.id,
                directory: list.file.directory
              }
              this.$store.dispatch("storage/open",payloadFile)
              .then(()=> {
                this.$router.push(`/viewer/?id_f=${list.file.id}&dir_f=${list.file.directory}&wgId=${this.$route.query.wgId}`)
              })
          }else if(Helper.isImages(list.file.extension)){
              var payloadImg = {
                _id: list.file.id,
                directory: list.file.directory
              }
              this.$store.dispatch("storage/getLinkFile",payloadImg);
              this.$store.dispatch("storage/fileretrieve",{_id : list.file.id,directory:list.file.directory});
                let imageView = document.getElementById("imageView");
                imageView.style.display = 'block';
          }else if(Helper.isVideos(list.file.extension)){
              this.$store.dispatch("storage/fileretrieve",{_id : list.file.id,directory:list.file.directory})
              .then(c=>{
                let video = document.getElementById("VideoView");
                video.style.display = 'block';
                this.$store.dispatch("storage/getLinkFile",c)
                .then((v)=>{
                  let videoPlayer = document.getElementById("videoPlayer");
                  videoPlayer.src = v;
                  videoPlayer.play();
                });
              });
          }else if(Helper.isAudios(list.file.extension)){
              this.$store.dispatch("storage/fileretrieve",{_id : list.file.id,directory:list.file.directory})
              .then(c=>{
                let audio = document.getElementById("AudioView");
                audio.style.display = 'block';
                this.$store.dispatch("storage/getLinkFile",c)
                .then((v)=>{
                  let audioPlayer = document.getElementById("audioPlayer");
                  audioPlayer.src = v;
                  audioPlayer.play();
                });
              });
          }else{
            this.$store.dispatch("storage/download",
            {
                directory : list.file.directory,
                hash_name : list.file.hash_name,
                file_name : list.file.file_name
            }
            );
          }
    },
    deleteChat(data){
      this.item = data
      this.$store.commit('chat/SET_DELETE_MESSAGE', data)
      this.$root.$emit('bv::show::modal', 'modal-delete-message')
    },
    isValidUrl(text){
      const regexUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
      return regexUrl.test(text)
    },
    openListFiles(){
      this.$ga.event(process.env.VUE_APP_GA_WG_CHAT_GET_FILE, 'WG - Click Attach File in Chat', 'wg-chat')
      this.$root.$emit('bv::show::modal', 'modalSendFileToChat')
      this.$store.dispatch("workgroup/listing", 'noclear')
    },
    groupingChat(array, key){
      return array.reduce((result, currentItem) => {
      // If an array already present for key, push it to the array. Otherwise create an array and push the object.
      (result[currentItem[key]] = result[currentItem[key]] || []).push( currentItem );
      // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
      return result;
      }, {})
    },
    loadHistories(){
      const sideBarValue = this.$store.getters['storage/sideRightToggle'];
      this.$store.dispatch('storage/setSideRightToggle',{isShowing: sideBarValue, isPersistent: true})
      this.$store.dispatch('chat/loadHistory')
    },
    convertDate(timestamp){
      return moment(timestamp * 1000).format("HH:mm A")
    },
    convertToDay(timestamp){
      return moment(timestamp * 1000).format("DD-MM-YYYY")
    },
    sendButton() {
      this.$ga.event(process.env.VUE_APP_GA_WG_CHAT_BTN_SEND, 'WG - Click Button Send in Chat', 'wg-chat')
      this.addMessage()
      this.text = ''
      this.$nextTick(() => {
        let messageDisplay = this.$refs.chatArea
        messageDisplay.scrollTop = messageDisplay.scrollHeight
      })
    },
    sendMessage(e) {
      this.$ga.event(process.env.VUE_APP_GA_WG_CHAT_BTN_SEND, 'WG - Click Button Send in Chat', 'wg-chat')
      if (e.shiftKey == true && e.key == "Enter") {
        return;
      }
      this.addMessage()
      this.text = ''
      e.target.style.height = '44px'
      this.$nextTick(() => {
        // this.scrollToEnd()
        let messageDisplay = this.$refs.chatArea
        messageDisplay.scrollTop = messageDisplay.scrollHeight
      })
    },
    scrollToEnd() {
      var element = document.querySelector(".chatBox");
      var scrollHeight = element.scrollHeight;
      return element.scrollTop = scrollHeight
    },
    addMessage() {
      let textMessage = this.text
      // let textMsg = textMessage.split(' ')
      // textMsg = textMsg.map(element => {
        // if(element.length > 32){
        //   let newElement = element.replace(/.{32}/g, '$&\n')
        //   newElement.split('\n')
        //   return newElement
        // }
      //   if(element.includes("http")){
      //     let newElement = `<a href="${element}" target="_blank">` + ' ' + element + ' ' + '</a>'
      //     return newElement
      //   }
      //   return element
      // });
      // textMessage = textMsg.join(' ')
      textMessage = textMessage.replace(/\n/g, " <br>")
      this.text = textMessage
      // const message = {
      //   name: this.name,
      //   time: this.time,
      //   message: textMessage,
      //   user: this.currentUser
      // }
      // this.messages = this.messages.concat(message)
      if(this.text != ' <br>'){
        if(this.text != ''){
          this.$store.dispatch("chat/sendChat",this.text);
        }
      }
    },
    resize (e) {
      e.target.style.height = '44px'
      let scHeight = e.target.scrollHeight
      e.target.style.height = `${scHeight}px`
    },
    dropHandler(ev){

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        // if (ev.dataTransfer.files) {
        //   // Use DataTransferItemList interface to access the file(s)
        //   for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        //     // If dropped items aren't files, reject them
        //     if (ev.dataTransfer.files[i].kind === 'file') {
        //       var file = ev.dataTransfer.files[i].getAsFile();
        //     }
        //   }
        // } else {
          // Use DataTransfer interface to access the file(s)
        // }
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    getImage(filename){
      var storageUtils = new Storage();
      return storageUtils.getImage(filename.substr(filename.lastIndexOf('.') + 1))
    },
    getSize(bytes){
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
  },
  mounted() {
    this.$store.commit('state/SET_STATE', 'workgroup')
    this.getWorkgroup()
    this.$ga.event(process.env.VUE_APP_GA_WG_CHAT_LOAD_PAGE, 'WG - Access Chatting Page', 'wg-chat')
    // if(this.state == 'workgroup'){
    // }
    this.$root.$on('bv::dropdown::show', bvEvent => {
        if(bvEvent.componentId === 'sub-btn-chat-me-id') {
            this.isSubBtnChatMe = true;
        }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
        if(bvEvent.componentId === 'sub-btn-chat-me-id') {
            this.isSubBtnChatMe = false;
        }
        if(this.isSubBtnChatMe) {
            bvEvent.preventDefault()
        }
    })
    this.$root.$on('bv::dropdown::show', bvEvent => {
        if(bvEvent.componentId === 'sub-btn-chat-friend-id') {
            this.isSubBtnChatFriend = true;
        }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
        if(bvEvent.componentId === 'sub-btn-chat-friend-id') {
            this.isSubBtnChatFriend = false;
        }
        if(this.isSubBtnChatFriend) {
            bvEvent.preventDefault()
        }
    })
    // if(this.state == 'storage'){
    //   this.$router.push({name: 'home'})
    // }
    
    this.$store.dispatch('chat/isConnectedSocket')
    .then((res)=>{
      if(res == false){
        this.$store.dispatch('user/getSocket')
        .then(() => {
          this.$store.dispatch('chat/openChat')
        })
      }
    })
    
    this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
    this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
    this.$store.commit("storage/SET_ALL_SELECTED",[]);
    const sideBarValue = this.$store.getters['storage/sideRightToggle'];
    this.$store.dispatch('storage/setSideRightToggle',{isShowing: sideBarValue, isPersistent: true});

    // this.$store.dispatch('chat/sortPerDay', this.allChat)
    this.scrollToEnd()
    // this.chatByDay()
  },
  updated() {
    this.scrollToEnd()
  }
}
</script>

<style scoped>
.dcrypt-note-chat{
  position: absolute;
  bottom: 0;
  right: 0;
}
.page-content {
    padding: 0;
    position: relative;
    max-width: 100%;
    width: 100%;
    margin-top:90px;
    height: calc(100vh - 90px);
    display: flex;
}
.container-fluid {
    position: relative;
    padding: 0;
    margin: 0 50px 0 0;
    transition: 0.8s;
}
.header {
    position: relative;
    height: 45px;
    width: 100%;
    padding-top: 7px;
}
.header .line-seperate {
    margin-top: -17px;
}
.header p{
    padding: 0px 0px 0px 24px;
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: #919394;
    font-family: 'Plus Jakarta Sans';
}
.header p span{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #262A2C;
    margin-right: 25px;
}
.horizontal-line{
    border: none;
    height: 0.5px;
    background: #E9EAEA;
    margin: 30px 0 20px 0;
    padding: 0.3px;
}
.chatBoxEmpty {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 20px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}
.emptyMsg{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 142px;
}
.emptyMsgInfo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.emptyMsgInfo p{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -1px;
  color: #262A2C;
  display: flex;
  margin: 25px 0 0 0;
  padding: 0;
}
.emptyMsgInfo p span{
  opacity: 0.5;
  padding: 0;
  margin-top: -22px;
}
.chatBox {
    position: relative;
    overflow-y: auto;
    height: calc(100% - 145px);
    padding: 5px 16px 5px 24px;
    overflow-y: auto;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  margin: 2px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.more-btn-chat-me{
  margin-right: 4px;
  opacity: 0;
  margin-top: 8px;
}
.more-btn-chat-friend{
  margin-left: 4px;
  margin-top: 4px;
  opacity: 0;
}
.chatBox .btnLoad{
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  font-weight: 400;
}
.chatBox .days{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chatBox .days p{
    font-size: 12px;
    background-color: #E9EAEA;
    padding: 2px 8px;
    border-radius: 64px;
}
.chatBox p{
    margin: 0;
}
.chatBox .myTime {
    display: flex;
    justify-content: flex-end;
    color: #919394;
    font-family: 'Plus Jakarta Sans';
    line-height: 16px;
    font-size: 12px;
    letter-spacing: -0.02em;
    font-weight: 400;
}
.message{
    position: relative;
    display: flex;
    width: 100%;
    margin: 5px 0;
    color: #262A2C;
    word-wrap: break-word;
}
.message div{
    position: relative;
    right: 0;
    text-align: left;
    max-width: 35%;
    padding: 8px;
    background: #F6F6FC;
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: 300;
    border-radius: 8px;
    display: block;
    margin-top: 16px;
    color: #919394;
}
.message div .files-in-chat-display{
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
  border-radius: 64px;
  padding: 8px 16px;
  cursor: pointer;
}

.halfOpacity{
    color: #262A2C;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: -0.02em;
    opacity: 0.5;
}
.avatar{
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
}
.myAvatar b-avatar{
    justify-content: flex-start;
}
.friendAvatar{
    justify-content: flex-end;
}
.myMessage:hover .more-btn-chat-me{
  opacity: 1;
  cursor: pointer;
}
.myMessage div{
    position: relative;
    right: 0;
    text-align: left;
    max-width: 45%;
    padding: 8px;
    background: #E9F5FB;
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    display: block;
    margin-top: 16px;
    justify-content: flex-end;
    letter-spacing: -0.02em;
    font-family: 'Plus Jakarta Sans';
}
.myMessage div .textBlack{
    color: #262A2C;
}
.myMessage div .textDeleted{
    color: #262A2C;
    letter-spacing: -0.02em;
    font-family: 'Plus Jakarta Sans';
    opacity: 0.5;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}
.myMessage {
    justify-content: flex-end;
}
.myTime{
    position: relative;
    display: block;
}
.friendMessage:hover .more-btn-chat-friend{
  opacity: 1;
  cursor: pointer;
}
.friendMessage div .textBlack{
    justify-content: flex-start;
    color: #262A2C;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 20px;
}
.friendMessage div .textDeleted{
    justify-content: flex-start;
    color: #919394;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 20px;
}
.friendTime{
    position: relative;
    display: block;
    color: #919394;
    font-family: 'Plus Jakarta Sans';
    line-height: 16px;
    font-size: 12px;
    letter-spacing: -0.02em;
    font-weight: 400;
    margin-left: 32px;
}


.chatInput {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 100%;
    max-width: 100%;
    height: 100px;
    background: none;
    padding: 5px 0 15px 0;
    flex: 0 1 auto;
}
.chatInput div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.chatInput div textarea {
    padding: 6px 6px 6px 45px;
    width: 100%;
    margin: 0px 20px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #E9EAEA;
    border-radius: 5px;
    font-size: 16px;
    resize: none;
    outline: none;
    max-height: 87px;
    overflow-y: auto;
}
.chatInput div textarea:focus{
  border: 1px solid #00AAFF !important;
}
.chatInput div textarea:valid{
  border: 1px solid #E9EAEA;
}
.btn-send-chat-v3:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.btn-send-chat-v3{
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
    margin-right: 36px;
    padding: 8px 16px;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
}
.btn-send-chat-v3:disabled{
    border-radius: 4px;
    border: none;
    background: #E9EAEA;
    color: #919394;
    margin-right: 36px;
    padding: 8px 16px;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.chatInput textarea:is(:focus, :valid){
    border-width: 2px;
    /* padding: 14px; */
    border-color: #bfbfbf;
}
.chatInput textarea::-webkit-scrollbar{
    width: 0;
}
.chatInput div img {
    position: absolute;
    left: 23px;
    bottom: 19px;
}


@media (max-width: 777px){
  .avatar{
    display: none !important;
  }
  .dates{
    display: none !important;
  }
  .message p {
    max-width: 85% !important;
    font-size: 12px !important;
  }
}

@media (max-width: 513px){
  .avatar{
    display: none !important;
  }
  .dates{
    display: none !important;
  }
  .message p {
    max-width: 85% !important;
  }
}
</style>